import { getWindowLocationOrigin } from '../../../utils/window'
import { generateMetaTags } from '../../../utils/helmet'

const title = 'SketchUp VR Plugin | Prospect by IrisVR'
const description = `Bring SketchUp models into VR in 1 click with Prospect by IrisVR. Host immersive design reviews and VR meetings. Imports Textures, Scenes, Layers, and more.`
const url = `${getWindowLocationOrigin()}/sketchup`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
